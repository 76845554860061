import customFetch from "services/api";

const ForgetPass = ({ setPage, setData }) => {
  const handleSubmit = async (ev) => {
    ev.preventDefault();

    const formData = new FormData(ev.target);
    const email = formData.get("email");

    try {
      const res = await customFetch(`admins/forget-password`, {
        method: "POST",
        body: { email },
      });
      if (res.status) {
        setPage(2);
        setData((prev) => ({ ...prev, email }));
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div className="flex w-full flex-col justify-center px-4 lg:px-28">
      <h1 className="text-[#161313] text-[48px] font-bold leading-normal mb-7 lg:text-[40px] md:text-[36px]">
        نسيت كلمة المرور؟
      </h1>
      <form className="w-full max-w-md lg:max-w-lg" onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          placeholder="البريد الالكترونى"
          className="w-full h-[55px] rounded-lg border border-[#192252] px-6 text-[#161313] text-[16px] mt-4 placeholder:text-[#161313]/60 focus:outline-none"
          required
        />
        <button
          type="submit"
          className="w-full rounded-lg bg-[#006cfd] py-3 mt-8 text-white text-[16px] font-bold transition duration-300 hover:bg-[#005bd3]"
        >
          ارسال
        </button>
      </form>
    </div>
  );
};

export default ForgetPass;
