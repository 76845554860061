import { message } from "antd";
import { LANGUAGE, translate } from "./general";

const unauthorizedCode = [401, 403, 410];

export const handleErrors = (errorResponse, status) => {
  if (status === 403) {
    window.location.href = `/${LANGUAGE}/access-denied`;
  }
  if (unauthorizedCode.includes(errorResponse.status_code)) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    return;
  }
  if (errorResponse.status_code === 101) {
    message.error("Outdated Version");
    message.info("Please refresh the page to update the app");
    return;
  }
  if (
    errorResponse.data &&
    errorResponse.data.errors &&
    errorResponse.status_code === 409
  ) {
    const errors =
      errorResponse?.data?.errors || errorResponse?.data || errorResponse;
    Object.keys(errors).forEach((field) => {
      if (typeof errors[field] === "string") {
        return message.error(errors[field]);
      } else {
        errors[field].forEach((error) => {
          return message.error(error);
        });
      }
    });
  }

  if (errorResponse.status_code === 404) {
    message.error("Not Found");
  }

  if (errorResponse.status_code === 500 || status === 500) {
    message.error(translate("حدث خطأ فني", "Internal Server Error"));
  }

  if (errorResponse.status_code === 508) {
    message.error("Time Out");
  }
  if (errorResponse.status_code === 400) {
    message.error(errorResponse.data);
  }
};
