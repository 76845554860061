import { useState } from "react";
import { message } from "antd";
import customFetch from "services/api";

const handleCache = (method, setData, response, id) => {
  if (method === "POST") {
    setData &&
      setData((prev) =>
        Array.isArray(prev) ? [...prev, response?.data] : response?.data
      );
  } else if (method === "DELETE") {
    setData && setData((prev) => [...prev.filter((p) => p.id !== id)]);
  } else if (method === "PUT") {
    setData &&
      setData((prev) => [
        ...prev.map((p) => (p.id === id ? response?.data : p)),
      ]);
  }
};

const useManageRequest = ({ onCreate, onDelete, onUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const create = async (url, formData) => {
    setLoading(true);
    try {
      const response = await customFetch(url, {
        method: "POST",
        body: formData,
      });

      if (response.status) {
        onCreate && onCreate(response.data);
        if (typeof response?.data === "string" && !response?.message) {
          setSuccessMessage(response?.data);
        } else {
          setSuccessMessage(response?.message);
        }
        setSuccessOpen(true);
      }
    } catch (error) {
      message.error("Network error: " + error.message);
    } finally {
      setLoading(false);
    }
  };
  const update = async (url, formData) => {
    setLoading(true);
    try {
      const response = await customFetch(url, {
        method: "PUT",
        body: formData,
      });

      if (response.status) {
        onUpdate && onUpdate(response.data);
        if (typeof response?.data === "string" && !response?.message) {
          setSuccessMessage(response?.data);
        } else {
          setSuccessMessage(response?.message);
        }
        setSuccessOpen(true);
      }
    } catch (error) {
      message.error("Network error: " + error.message);
    } finally {
      setLoading(false);
    }
  };
  const deleteItem = async (url, id) => {
    setLoading(true);
    try {
      const response = await customFetch(url, {
        method: "DELETE",
      });

      if (response.status) {
        onDelete && onDelete(id);
        if (typeof response?.data === "string" && !response?.message) {
          setSuccessMessage(response?.data);
        } else {
          setSuccessMessage(response?.message);
        }
        setSuccessOpen(true);
      } else {
        // handleErrors(response);
        message.error(`Deleting ${module} failed`);
      }
    } catch (error) {
      message.error("Network error: " + error.message);
    } finally {
      setLoading(false);
    }
  };
  const doRequest = async (
    url,
    navigateUrl,
    method,
    module,
    formData,
    setData,
    id,
    notHasAlert = false
  ) => {
    setLoading(true);
    try {
      const response = await customFetch(url, {
        method: method,
        body: formData,
      });

      if (response.status) {
        !notHasAlert && message.success(`${module} saved successfully`);
        handleCache(method, setData, response, id);
        if (typeof response?.data === "string" && !response?.message) {
          setSuccessMessage(response?.data);
        } else {
          setSuccessMessage(response?.message);
        }
        setSuccessOpen(true);
      } else {
        // handleErrors(response);
        message.error(`Creating ${module} failed`);
      }
    } catch (error) {
      message.error("Network error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    doRequest,
    loading,
    create,
    update,
    deleteItem,
    successMessage,
    successOpen,
    setSuccessOpen,
  };
};

export default useManageRequest;
