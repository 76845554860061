import { useTranslation } from "react-i18next";
import LoginForm from "../login/LoginForm";
import RightSwiper from "../login/RightSwiper";
import Meta from "services/Meta";
import ForgetPass from "./ForgetPass";
import { useState } from "react";
import OTP from "./OTP";
import ResetPassword from "./ResetPassword";

const ForgetPassword = () => {
  const { t } = useTranslation();
  let [page, setPage] = useState(1);
  const [data, setData] = useState({});

  const handlePage = () => {
    switch (page) {
      case 1:
        return <ForgetPass setPage={setPage} setData={setData} />;
      case 2:
        return <OTP setPage={setPage} setData={setData} data={data} />;
      case 3:
        return <ResetPassword data={data} />;
    }
  };

  return (
    <div className="w-full flex items-center h-screen lg:h-auto">
      <Meta title={t("pages.login")} />
      <RightSwiper />
      <div className="flex-col justify-center items-center lg:w-1/2 w-full lg:block">
        {handlePage()}
      </div>
    </div>
  );
};

export default ForgetPassword;
