import { useEffect, useState } from "react";
import Navbar from "./navbar";
import SideBar from "./sidebar";
import { useLocation } from "react-router-dom";

const AppLayout = ({ children }) => {
  let [isOpen, setIsOpen] = useState(window.innerWidth > 1280);
  let { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (window.innerWidth < 1280) {
      setIsOpen(false);
    }
  }, [pathname]);

  return (
    <>
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="flex">
        <SideBar isOpen={isOpen} setIsOpen={setIsOpen} />
        <div className="w-full lg:px-[30px] px-[4%] pt-[42px]">{children}</div>
      </div>
    </>
  );
};
export default AppLayout;
