import React, { useState } from "react";
import { InputOTP } from "antd-input-otp";
import customFetch from "services/api";

const OTP = ({ setPage, setData, data }) => {
  const [otp, setOtp] = useState("");

  const handleChange = (value) => {
    setOtp(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const code = otp.join("");
    const updatedData = { ...data, code };

    try {
      const res = await customFetch(`admins/check-otp`, {
        method: "POST",
        body: updatedData,
      });
      if (res.status) {
        setPage(3);
        setData(updatedData);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div className="relative flex h-screen">
      <div className="flex w-full flex-col justify-center px-4 lg:px-28">
        <h1 className="text-[#161313] text-[48px] font-bold leading-normal mb-7 lg:text-[40px] md:text-[36px]">
          تأكيد رقم الجوال
        </h1>
        <form onSubmit={handleSubmit}>
          <div className="flex justify-center mt-4">
            <InputOTP
              value={otp}
              onChange={handleChange}
              length={4}
              autoFocus
              inputClassName="h-[32px] border border-[#161313] rounded-lg text-center text-[20px] font-semibold focus:outline-none focus:ring-2 focus:ring-[#006cfd]"
            />
          </div>
          <button
            type="submit"
            className="w-full rounded-lg bg-[#006cfd] py-3 mt-8 text-white text-[16px] font-bold transition duration-300 hover:bg-[#005bd3]"
          >
            ارسال
          </button>
        </form>
      </div>
    </div>
  );
};

export default OTP;
