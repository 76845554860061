import { Button, Checkbox, Form, Input, message } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import customFetch from "../../../services/api";
import { useTranslation } from "react-i18next";

const LoginForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lng = localStorage.getItem("default-lg");

  const onFinish = async (values) => {
    const response = await customFetch("dashboard/login", {
      body: values,
      method: "POST",
    });
    if (response.status) {
      const token = response.data.access_token;
      const user = response.data;
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      message.success(t("login_success"));
      navigate(`/${lng}/home`);
      window.location.reload();
    } else {
      message.error(t("login_err"));
    }
  };
  return (
    <section className="w-full px-[8%] lg:px-[12%] xl:px-[16%] lg:relative lg:top-10 h-full">
      <h1 className="font-bold text-[38px] 2xl:text-[48px] leading-[57.6px] mb-[13px]">
        {t("login")}
      </h1>
      <div className="2xl:text-[20px] text-lg mb-[41px] ">
        <span className="leading-6">{t("dont_have_acc")}</span>
        {"  "}
        <Link to={"/"} className="font-bold leading-6 text-[#006CFD]">
          {t("make_acc")}
        </Link>
      </div>
      <Form form={form} onFinish={onFinish} className="m-auto login-form">
        <Form.Item
          name="mobile"
          rules={[{ required: true, message: t("login_mobile_err") }]}
        >
          <Input placeholder={t("login_mobile_holder")} type="number"></Input>
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: t("login_password_err") }]}
        >
          <Input.Password
            placeholder={t("login_password_holder")}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          ></Input.Password>
        </Form.Item>
        <Checkbox className="checkbox">{t("remeber")}</Checkbox>
        <br />
        <Button className="" type="primary" htmlType="submit">
          {t("login")}
        </Button>
      </Form>
      <div className="flex justify-center">
        <Link
          to={"/forget-password"}
          className="text-[#161313] text-[17px] mt-7 2xl:mt-[37px] 2xl:mb-[62px] mb-4 underline "
        >
          {t("forget_password")}
        </Link>
      </div>
      <p className="text-[15px] text-[#404040] text-center">
        {t("login_continue")}
        <span className="font-bold">{t("login_rights")}</span>
        {t("us")}
      </p>
    </section>
  );
};

export default LoginForm;
