import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { asideLinks } from "../../constants/asideLinks";
import { useTranslation } from "react-i18next";
import { translate } from "services/general";
import { HAS_PERMISSION } from "services/user";

const SideBar = ({ isOpen }) => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const [active, setActive] = useState(0);
  let ref = useRef();
  let aside = useRef();
  useEffect(() => {
    if (!isOpen) {
      aside.current.classList.add("hidden");
    } else {
      window.dispatchEvent(new Event("resize"));
    }
  }, [isOpen]);

  return (
    <>
      <aside
        className={`xl:sticky fixed  ${
          isOpen ? "right-0" : "opacity-0 -right-[400px]"
        } h-[100vh] top-0 overflow-y-auto xl:w-[288px] w-full pe-[22px] ps-[13px] xl:gradient-sidebar-bg bg-[#e4fdff] duration-500 bar z-[700]`}
        ref={aside}
      >
        <ul className="overflow-y-auto mt-5">
          {asideLinks.map(
            ({ en_text, text, path, icon, activeIcon, children, id }, i) => {
              return !children ? (
                <Fragment>
                  {HAS_PERMISSION(id) ? (
                    <Link to={path} key={i}>
                      <li
                        className={`flex gap-[10px] py-[14px] hover:bg-white px-[10px] rounded-[5px] duration-200 cursor-pointer ${
                          pathname == path
                            ? "font-bold bg-white"
                            : "font-normal"
                        }`}
                      >
                        {pathname == path ? activeIcon : icon}
                        {t(i18n.language == "ar" ? text : en_text)}
                      </li>
                    </Link>
                  ) : (
                    ""
                  )}
                </Fragment>
              ) : (
                <section key={i}>
                  {children.some(({ id }) => HAS_PERMISSION(id)) && (
                    <>
                      <div
                        className="flex justify-between items-center cursor-pointer hover:bg-white duration-200 py-[14px] px-[8px] rounded-[5px]"
                        onClick={() => {
                          if (i === active) {
                            setActive(0);
                          } else {
                            setActive(i);
                          }
                        }}
                      >
                        <li className="flex gap-[10px]">
                          {pathname === path ? activeIcon : icon}
                          {t(i18n.language === "ar" ? text : en_text)}
                        </li>
                        <img
                          src="/icons/Arrows.svg"
                          alt="Arrows icon"
                          className={`${
                            active !== i ? "rotate-0" : "rotate-180"
                          } relative`}
                        />
                      </div>
                      <div
                        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-[#646370] text-sm md:text-[15px] md:leading-[31px] leading-[25px] font-medium mb-[22px] md:mb-[32px] cursor-pointer ${
                          active === i
                            ? "grid-rows-[1fr] opacity-100"
                            : "hidden"
                        }`}
                      >
                        {children.map(({ en_text, text, path, id }) => (
                          <Fragment key={id}>
                            {HAS_PERMISSION(id) && (
                              <Link
                                to={path}
                                ref={ref}
                                className="overflow-hidden py-1 hover:bg-white rounded-md px-[10px]"
                              >
                                {translate(t(text), t(en_text))}
                              </Link>
                            )}
                          </Fragment>
                        ))}
                      </div>
                    </>
                  )}
                </section>
              );
            }
          )}
        </ul>
      </aside>
    </>
  );
};

export default SideBar;
