import { useState, useEffect } from "react";
import customFetch from "services/api";

const buildQueryString = (search) => {
  const params = new URLSearchParams();

  Object.keys(search).forEach((key) => {
    if (search[key]) {
      params.append(key, search[key]);
    }
  });

  return params.toString();
};

const useFetch = (url, page = null, searchParam = null, otherParam) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 15,
    total: 0,
  });
  useEffect(() => {
    if (!url) {
      return;
    }
    const fetchData = async () => {
      setLoading(true);
      try {
        const searchParams = searchParam ? buildQueryString(searchParam) : "";
        const pageParam = page ? `?page=${page}` : "";
        const othersParams = otherParam ? buildQueryString(otherParam) : "";
        const finalUrl = `${url}${pageParam}${
          searchParams ? `${page ? "&" : "?"}${searchParams}` : ""
        }${othersParams ? `&${othersParams}` : ""}`;

        const response = await customFetch(finalUrl);
        if (response.status) {
          if (response.data.data) {
            const { data, total } = response.data;
            setData(data);
            setPagination((prev) => ({
              ...prev,
              current: page,
              total: total,
            }));
            setTotal(total);
          } else {
            const { data, total } = response;
            setData(data);
            setTotal(total);
          }
        } else {
          setError("An error occurred during the fetch");
        }
        setLoading(false);
      } catch (error) {
        setError("Error fetching data: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, page, searchParam, otherParam]);

  return { pagination, data, fetchLoading: loading, error, setData, total };
};

export default useFetch;
