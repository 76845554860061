import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import customFetch from "services/api";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const ResetPassword = ({ data }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    const formData = new FormData(ev.target);
    const password = formData.get("password");
    const password_confirmation = formData.get("password_confirmation");

    try {
      const res = await customFetch(`admins/change-forget-password`, {
        method: "POST",
        body: {
          password,
          password_confirmation,
          ...data,
        },
      });
      if (res.status) {
        navigate("/login");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div className="relative flex h-screen">
      <div className="flex w-full flex-col justify-center px-4  lg:px-28">
        <h1 className="text-[#161313] text-[48px] font-bold leading-normal mb-7 lg:text-[40px] md:text-[36px]">
          إعادة تعيين كلمة المرور
        </h1>
        <form className="w-full max-w-md lg:max-w-lg" onSubmit={handleSubmit}>
          <div className="relative mt-4">
            <input
              id="password-field"
              type={showPassword ? "text" : "password"}
              placeholder="كلمة المرور"
              className="w-full h-[55px] rounded-lg border border-[#192252] px-6 text-[#161313] text-[16px] placeholder:text-[#161313]/60 focus:outline-none"
              required
              minLength={6}
              name="password"
            />
            <button
              type="button"
              className="absolute left-6 top-1/2 -translate-y-1/2 text-gray-400"
              onClick={() => setShowPassword((prev) => !prev)}
            >
              {showPassword ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
            </button>
          </div>

          <div className="relative mt-4">
            <input
              id="password-field2"
              type={showConfirmPassword ? "text" : "password"}
              placeholder="تأكيد كلمة المرور"
              className="w-full h-[55px] rounded-lg border border-[#192252] px-6 text-[#161313] text-[16px] placeholder:text-[#161313]/60 focus:outline-none"
              required
              minLength={6}
              name="password_confirmation"
            />
            <button
              type="button"
              className="absolute left-6 top-1/2 -translate-y-1/2 text-gray-400"
              onClick={() => setShowConfirmPassword((prev) => !prev)}
            >
              {showConfirmPassword ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
            </button>
          </div>

          <button
            type="submit"
            className="w-full rounded-lg bg-[#006cfd] py-3 mt-8 text-white text-[16px] font-bold transition duration-300 hover:bg-[#005bd3]"
          >
            تعيين كلمة المرور
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
