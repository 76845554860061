import { Navigate, Route, Routes } from "react-router-dom";
import { Suspense, lazy, useEffect } from "react";
import { protectedRoutes } from "./routes";
import { LANGUAGE } from "services/general";
import SuccessPayment from "components/components/make-promotion/SuccessPayment";
import { useFetch } from "hooks";
import LoadingPage from "components/shared/LoadingPage";

const importComponent = (path) => {
  return lazy(() => import(`../${path}`));
};

const protectedRouteComponents = protectedRoutes.map(({ path, filePath }) => ({
  path: `/:type/${path.toLowerCase()}`,
  component: importComponent(`components/${filePath}`),
}));

const AppRoutes = () => {
  const { data: profile } = useFetch("dashboard/profile");

  useEffect(() => {
    if (profile) {
      localStorage.setItem("user", JSON.stringify(profile));
    }
  }, [profile]);

  return (
    <Routes>
      {protectedRouteComponents.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <Suspense fallback={<LoadingPage />}>
              <route.component />
            </Suspense>
          }
        />
      ))}

      <Route
        path="*"
        exact={true}
        element={<Navigate replace to={`/${LANGUAGE}/home`} />}
      />
    </Routes>
  );
};
export default AppRoutes;
