import { useNavigate, useLocation } from "react-router-dom";
import { toQueryString } from "services/general";

const useSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClickSearchIcon = (isSearch, setSearchParam, setIsSearch) => {
    if (isSearch) {
      setSearchParam({});
      navigate({
        pathname: location.pathname,
        search: ``,
      });
    }
    setIsSearch(!isSearch);
  };
  const handleAddSearch = (value) => {
    let PAGE = 1;
    const queryParams = new URLSearchParams(location.search);
    const searchParams = {};
    queryParams.forEach((value, key) => {
      if (key === "page") {
        PAGE = value;
      } else {
        searchParams[key] = value;
      }
    });
    navigate({
      pathname: location.pathname,
      search: `?${toQueryString({
        ...searchParams,
        ...value,
      })}&page=${PAGE}`,
    });
  };
  return { handleClickSearchIcon, handleAddSearch };
};
export default useSearch;
