import { Spin } from "antd";
import React from "react";

const LoadingPage = () => {
  return (
    <div className="flex items-center justify-center h-[80vh]">
      <Spin size="large" />
    </div>
  );
};

export default LoadingPage;
