import { useEffect } from "react";

const useUnitFormSetup = (
  id,
  unit,
  form,
  formTwo,
  setImages,
  setPropertyId,
  setFiles
) => {
  useEffect(() => {
    if (id && unit) {
      const {
        name,
        description,
        number,
        property_id,
        rent_amount,
        rent_cycle: { id },
        images,
        custom_fields_data,
        taxes_id,
        attachments,
        listing_title,
      } = unit;
      setImages(images);
      form.setFieldsValue({
        rent_amount,
        rent_cycle: id,
        name,
        number,
        description,
        propert_id: property_id,
        taxes_id,
        listing_title,
      });
      const prosData = custom_fields_data?.reduce((acc, item) => {
        acc[item.custom_field.id] =
          item?.custom_field?.type == "select"
            ? item?.custom_field_option_id
            : item?.content;
        return acc;
      }, {});

      formTwo.setFieldsValue({ ...prosData });
      setPropertyId(unit?.property?.id);
      setFiles(attachments);
    }
  }, [form, formTwo, id, setImages, unit, setPropertyId, setFiles]);
};

export default useUnitFormSetup;
