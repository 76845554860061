import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useUpdateSearch = (setSearchParam, setPage = null, form) => {
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchParams = {};

    queryParams.forEach((value, key) => {
      if (key === "page") {
        setPage && setPage(Number(value));
      } else {
        searchParams[key] = value;
      }
    });
    setSearchParam(searchParams);
    form && form.setFieldsValue(searchParams);
  }, [location.search, setSearchParam, setPage, form]);
};

export default useUpdateSearch;
