import { useEffect } from "react";

const usePropertyFormSetup = (
  id,
  property,
  formPropertyInfo,
  setHasUnits,
  unitForm,
  propsForm,
  setCenter,
  formPropertyLocation,
  setImages,
  setType,
  setPropertyType,
  setFiles,
  setCityId,
  setMarkerPosition,
  setFileList
) => {
  useEffect(() => {
    if (id && property) {
      const {
        has_units,
        name,
        description,
        property_type_id,
        units,
        city_id,
        district_id,
        street,
        number,
        extra_no,
        postal_code,
        latitude,
        longitude,
        cover_url,
      } = property;
      setPropertyType(property_type_id);
      formPropertyInfo.setFieldsValue({
        name,
        description,
        type_id: property_type_id,
      });
      formPropertyLocation.setFieldsValue({
        city_id,
        district_id,
        street,
        number,
        extra_no,
        postal_code,
      });
      setCenter({ lat: Number(latitude), lng: Number(longitude) });
      setMarkerPosition({ lat: Number(latitude), lng: Number(longitude) });
      setHasUnits(has_units);
      setCityId(city_id);
      if (has_units === 0) {
        setImages(units[0]?.images);
        const {
          rent_amount,
          rent_cycle,
          taxes_id,
          type,
          sell_amount,
          listing_title,
        } = units[0];
        unitForm.setFieldsValue({
          rent_amount,
          rent_cycle: rent_cycle?.id,
          taxes_id,
          type,
          sell_amount,
          listing_title,
        });
        setType(type);

        const prosData = units[0]?.custom_fields_data?.reduce((acc, item) => {
          acc[item.custom_field.id] =
            item?.custom_field?.type == "select"
              ? item?.custom_field_option_id
              : item?.content;
          return acc;
        }, {});
        propsForm.setFieldsValue({ ...prosData });
        setFiles(units[0]?.attachments);
      }
      if (has_units === 1) {
        setFileList([
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: cover_url,
          },
        ]);
      }
    }
  }, [
    id,
    property,
    formPropertyInfo,
    setHasUnits,
    unitForm,
    propsForm,
    setCenter,
    formPropertyLocation,
    setImages,
    setFiles,
    setPropertyType,
    setType,
    setCityId,
    setMarkerPosition,
    setFileList,
  ]);
};

export default usePropertyFormSetup;
