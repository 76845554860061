import { Download, Right } from "components/shared/icons";
import { useTranslation } from "react-i18next";
import { getFormattedDateTime } from "services/general";

const SuccessPayment = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center pt-[30px]">
      <Right />
      <h1 className="text-gray-900 font-bold text-xl mb-2">
        {t("paymentSuccess.title")}
      </h1>
      <p className="text-gray-500 text-[14px] font-medium leading-[20.41px] tracking-[-0.26px] mb-8">
        {t("paymentSuccess.description")}
      </p>
      <h2 className="text-gray-900 font-bold text-[14px] mb-4">
        {t("paymentSuccess.detailsTitle")}
      </h2>
      <table className="w-[387px] mx-auto rounded-[10px] custom-dashed">
        <tbody>
          <tr>
            <td className="text-black font-bold text-[12px] leading-[30.844px] pt-3 ps-[24px]">
              {t("paymentSuccess.labels.amount")}
            </td>
            <td className="text-black font-normal text-[12px] leading-[28.974px] pt-3 ps-[24px]">
              {data?.amount + " " + t("riyal")}
            </td>
          </tr>
          <tr>
            <td className="text-black font-bold text-[12px] leading-[30.844px] ps-[24px]">
              {t("paymentSuccess.labels.cardNumber")}
            </td>
            <td className="text-black font-normal text-[12px] leading-[28.974px] ps-[24px]">
              {data?.transaction_account_no}
            </td>
          </tr>
          <tr>
            <td className="text-black font-bold text-[12px] leading-[30.844px] ps-[24px]">
              {t("paymentSuccess.labels.transactionCode")}
            </td>
            <td className="text-black font-normal text-[12px] leading-[28.974px] ps-[24px]">
              {data?.transaction_no}
            </td>
          </tr>
          <tr>
            <td className="text-black font-bold text-[12px] leading-[30.844px] pb-3 ps-[24px]">
              {t("paymentSuccess.labels.requestTime")}
            </td>
            <td className="text-black font-normal text-[12px] leading-[28.974px] pb-3 ps-[24px]">
              {getFormattedDateTime(data?.created_at)}
            </td>
          </tr>
        </tbody>
      </table>
      <p className="text-gray-500 text-[12px] font-medium leading-[15.7px] tracking-[-0.2px] mt-7 mb-6">
        {t("paymentSuccess.advice")}
      </p>
    </div>
  );
};

export default SuccessPayment;

/**
 * 
 *   <button
onClick={() => window.print()}
className="bg-blue-600 text-white font-bold text-xs leading-[26px] tracking-[-0.048px] rounded-lg w-[350px] h-[45px] flex items-center justify-center gap-1 mx-auto"
>
<Download />
{t("paymentSuccess.buttonText")}
</button>
 */
